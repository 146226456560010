<template>
  <div class="feed-wrap">
    <div class="feed-list feed-column">
      <div class="admin-feed-block mySubscriberFeed">
        <Spacer num="1" />
        <FeedTitle :title="$locale['my_subscribers']" :subtitle="$locale['all_your_subscribers']" />
        <UserSubscribersList />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    UserSubscribersList: () => import("./UserSubscribersList.vue"),
  },
};
</script>
